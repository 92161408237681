<template>
  <v-row justify="space-around">
    <v-sheet
      class="pa-0 col-11 mt-5"
      outlined="outlined"
    >
      <v-card-title class="grey white--text">
        一問一答問題選択
      </v-card-title>
      <v-stepper
        v-model="step"
        non-linear="non-linear"
        vertical="vertical"
      >
        <v-stepper-step
          editable="editable"
          step="1"
        >
          カテゴリ
        </v-stepper-step>
        <v-stepper-content step="1">
          <select-category
            :loading.sync="loading"
            :api-failed.sync="apiFailed"
          />
          <v-row class="ma-2">
            <v-btn
              block="block"
              color="primary"
              dark="dark"
              @click="step=2"
            >
              次へ
            </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-step
          editable="editable"
          step="2"
        >
          {{ '出題試験選択' }}
        </v-stepper-step>
        <v-stepper-content step="2">
          <select-exam />
          <v-row class="ma-2">
            <v-btn
              block="block"
              color="primary"
              dark="dark"
              @click="step=3"
            >
              次へ
            </v-btn>
          </v-row>
        </v-stepper-content>
        <v-stepper-step
          editable="editable"
          step="3"
        >
          その他オプション
        </v-stepper-step>
        <v-stepper-content step="3">
          <select-option />
        </v-stepper-content>
      </v-stepper>
      <v-row justify="space-around">
        <v-btn
          class="ma-5"
          color="orange"
          large="large"
          dark="dark"
          min-width="300px"
          @click="getQuestions"
        >
          開始
        </v-btn>
      </v-row>
    </v-sheet>
    <v-flex>
      <question-ads
        class="mx-10"
        :ad-to-show="adToShow"
      />
    </v-flex>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate="indeterminate"
        size="64"
      />
    </v-overlay>
    <v-snackbar
      v-model="noQuestions"
      bottom="bottom"
      color="secondary"
    >
      該当する問題がありません
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="noQuestions = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="apiFailed"
      bottom="bottom"
      color="error"
    >
      データの取得に失敗しました、再度お試しください
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="apiFailed = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import SelectExam from "../components/SelectQuestions/selectExam";
import SelectOption from "../components/SelectQuestions/selectOption";
import SelectCategory from "../components/SelectQuestions/selectCategory";
import QuestionAds from "@/components/Ads/QuestionAds";

export default {
  name: 'SelectQuestions',
  components: { QuestionAds, SelectCategory, SelectOption, SelectExam, },
  data() {
    return {
      step: 1,
      loading: false,
      noQuestions: false,
      apiFailed: false,
      adToShow: 'BDO',
    }
  },
  computed: {
    ...mapGetters(['questions']),
  },
  methods: {
    async getQuestions() {
      this.loading = true;
      await this.$store.dispatch('getQuestions');
      this.loading = false;
      if (this.questions.length < 1) {
        this.noQuestions = true
      } else {
        await this.$router.push('/question/1');
      }
    },
  },
}
</script>

<style scoped>

.custom-text {
  font-size: 0.70rem;
  color: rgb(135, 135, 135);
}

</style>

<template>
  <v-sheet elevation="0">
    <v-row class="justify-start">
      <v-btn
        class="mr-0 ma-5"
        outlined="outlined"
        small="small"
        color="primary"
        @click="selectAll"
      >
        全て選択
      </v-btn>
      <v-btn
        class="ma-5"
        outlined="outlined"
        small="small"
        color="primary"
        @click="deselectAll"
      >
        選択解除
      </v-btn>
    </v-row>
    <div
      v-for="year in years"
      :key="year"
    >
      <v-row class="mx-2 mb-1">
        <div class="col-6 pa-0">
          <v-btn
            color="primary"
            tile="tile"
            depressed="depressed"
            block="block"
            :small="$vuetify.breakpoint.name == 'xs'"
            :outlined="!isSelected(groupedExams[year][0])"
            :text="!isSelected(groupedExams[year][0])"
            @click="toggleExamSelection(groupedExams[year][0])"
          >
            {{ groupedExams[year][0] }}
          </v-btn>
        </div>
        <div class="col-6 pa-0 pl-1">
          <v-btn
            v-if="groupedExams[year][1]"
            color="primary"
            tile="tile"
            depressed="depressed"
            block="block"
            :small="$vuetify.breakpoint.name == 'xs'"
            :outlined="!isSelected(groupedExams[year][1])"
            :text="!isSelected(groupedExams[year][1])"
            @click="toggleExamSelection(groupedExams[year][1])"
          >
            {{ groupedExams[year][1] }}
          </v-btn>
          <v-btn
            v-else
            tile="tile"
            disabled="disabled"
            block="block"
            :small="$vuetify.breakpoint.name == 'xs'"
          >
            <v-icon large="large">
              mdi-minus
            </v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
  import axios from "axios";

  export default {
    name: "SelectExam",
    data() {
      return {
        exams: [],
        selectedExams: [],
      }
    },
    computed: {
      groupedExams() {
        return this.exams.reduce((result, exam) => {
          const re = /(\D*\d+年).*/;
          let year = exam.match(re)[1];
          result[year] === undefined ? result[year] = [exam] : result[year].push(exam);
          return result
        }, {})
      },
      years() {
        return Object.keys(this.groupedExams).sort((a, b) => {
          const aGengo = a.slice(0, 2)
          const bGengo = b.slice(0, 2)
          if (aGengo === bGengo) {
            return parseInt(b.slice(2)) - parseInt(a.slice(2))
          } else if (aGengo === '平成') {
            return 1
          } else {
            return -1
          }
        })
      }
    },
    watch: {
      selectedExams() {
        const payload = { exam: this.selectedExams, category: this.selectedCategories };
        this.$store.dispatch('updateSelectedConditions', payload);
      },
    },
    mounted() {
      this.fetchExams()
    },
    methods: {
      selectAll() {
        this.selectedExams = this.exams
      },
      deselectAll() {
        this.selectedExams = []
      },
      isSelected(exam) {
        return this.selectedExams.includes(exam);
      },
      toggleExamSelection(exam) {
        const eList = this.selectedExams;
        if (eList.includes(exam)) {
          this.selectedExams = eList.filter(x => x !== exam);
        } else {
          this.selectedExams.push(exam);
        }
      },
      fetchExams() {
        const url ='/api/v1/single_questions/exams';
        axios.get(url)
          .then((res) => {
            this.exams = res.data;
            this.selectedExams = res.data;
          });
      },
    }
  }
</script>

<style scoped>
  .exam-group {
    position: relative;
    margin: 12px auto;
    padding: 0.5em;
    color: #555555; /* 文章色 */
    background-color: #fff; /* 背景色 */
    border: 1px solid #555555; /* 枠線の太さ・色 */
    width: 90%;
  }

  .exam-group-title {
    position: absolute;
    padding: 0 .5em;
    left: 20px;
    top: -10px;
    background-color: #fff; /* タイトル背景色 */
    color: #555555; /* タイトル文字色 */
    font-size: small;
  }


</style>

<template>
  <v-row class="justify-space-around">
    <v-flex>
      <a
        v-show="adToShow !== 'google'"
        :href="link"
        style="max-height: 200px"
      >
        <v-img
          :src="imageSrc"
          eager="eager"
          contain="contain"
          max-height="150px"
        /></a>
    </v-flex>
    <div v-show="adToShow === 'google'">
      <div
        id="div-gpt-ad-1602663721734-0"
        class="mx-auto"
      />
    </div>
  </v-row>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "QuestionAds",
  props: {
    adToShow: {
      type: String,
      default: "google", // 'google'| 'CPA' | 'BDO'
    },
  },
  computed: {
    link() {
      if (this.adToShow === "CPA") {
        return "https://cpa-net.jp";
      } else if (this.adToShow === "BDO") {
        return "https://recruit.bdo.or.jp/";
      } else if (this.adToShow === "growth") {
        return "https://growth.recruit.co.jp/";
      } else {
        return "#";
      }
    },
    imageSrc() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (this.adToShow === "CPA") {
        return require("../../assets/cpa/kakomon_banner_2024.jpg");
      } else if (this.adToShow === "BDO") {
        const currentMonth = dayjs().month() + 1;
        if (4 <= currentMonth && currentMonth <= 9) {
          if (breakpoint === "xs") {
            if (Math.random() < 0.5) {
              return require("../../assets/bdo_ads/4-9/sp_02.png");
            } else {
              return require("../../assets/bdo_ads/4-9/sp_04.png");
            }
          } else {
            if (Math.random() < 0.5) {
              return require("../../assets/bdo_ads/4-9/pc_02.png");
            } else {
              return require("../../assets/bdo_ads/4-9/pc_04.png");
            }
          }
        } else {
          if (breakpoint === "xs") {
            if (Math.random() < 0.5) {
              return require("../../assets/bdo_ads/10-3/sp_01.png");
            } else {
              return require("../../assets/bdo_ads/10-3/sp_03.png");
            }
          } else {
            if (Math.random() < 0.5) {
              return require("../../assets/bdo_ads/10-3/pc_01.png");
            } else {
              return require("../../assets/bdo_ads/10-3/pc_03.png");
            }
          }
        }
      } else if (this.adToShow === "growth") {
        if (breakpoint === "xs") {
          return require("../../assets/growth/sp.png");
        } else {
          return require("../../assets/growth/pc.png");
        }
      } else {
        return "#";
      }
    },
  },
  mounted() {
    window.googletag.cmd.push(function () {
      window.googletag.display("div-gpt-ad-1602663721734-0");
      window.refreshQuestionAdsSlot();
    });
  },
};
</script>

<style scoped></style>

<template>
  <v-sheet elevation="0">
    <v-row class="justify-start">
      <v-btn
        class="mr-0 ma-5"
        outlined="outlined"
        small="small"
        color="primary"
        @click="selectAll"
      >
        全て選択
      </v-btn>
      <v-btn
        class="ma-5"
        outlined="outlined"
        small="small"
        color="primary"
        @click="deselectAll"
      >
        選択解除
      </v-btn>
    </v-row>
    <v-treeview
      v-model="selectedCategories"
      selectable="selectable"
      :selection-type="'leaf'"
      open-on-click="open-on-click"
      hoverable="hoverable"
      dense="dense"
      :items="categories"
    >
      <template v-slot:label="{ item }">
        <template v-if="!isLogin">
          <div
            class="text-wrap my-1"
            :class="{ custom: $vuetify.breakpoint.xs}"
          >
            {{ item.name }}
          </div>
        </template>
        <template v-else>
          <div
            class="text-wrap my-1 caption"
            :class="{ custom: $vuetify.breakpoint.xs}"
          >
            {{ item.name }}
          </div>
          <v-progress-linear
            height="20"
            color="pink lighten-5"
            background-color="grey lighten-3"
            :value="item.progress * 100"
          >
            <template>
              <small>{{ (item.progress * 100).toFixed(1) }}% ({{ item.solved_count }}/{{ item.question_count }})</small>
            </template>
          </v-progress-linear>
        </template>
      </template>
    </v-treeview>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from "axios";

export default {
  name: "SelectCategory",
  data() {
    return {
      categories: [],
      subCatIds: [],
      selectedCategories: [],
    }
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  watch: {
    selectedCategories() {
      const payload = { category: this.selectedCategories };
      this.$store.dispatch('updateSelectedConditions', payload);
    },
    isLogin() {
      this.fetchCategories()
    }
  },
  mounted() {
    this.fetchCategories()
  },
  methods: {
    selectAll() {
      this.selectedCategories = this.subCatIds
    },
    deselectAll() {
      this.selectedCategories = []
    },
    selectByCategoryName(categoryName) {
      if (!categoryName) return;
      const { id } = findCategory([...this.categories], categoryName)
      this.selectedCategories = [id];
    },
    async fetchCategories() {
      this.$emit('update:loading', true)
      const url = this.isLogin ? '/api/v1/users/progress' : '/api/v1/categories';

      await axios.get(url)
        .then((res) => {
          const categoryOrder = ['財務会計論', '管理会計論', '企業法', '監査論']
          categoryOrder.forEach(category => {
            const item = res.data.items.find(item => item.id === category)
            this.categories = [...this.categories, item]
          })
          this.subCatIds = res.data.sub_cat_ids;
          this.deselectAll()
        }).catch(() => {
          this.$emit('update:apiFailed', true);
        }).finally(() => {
            this.$emit('update:loading', false);
          }
        );
      this.selectByCategoryName(this.$route.query.category)
    }
  },
}

const findCategory = (categoryList, categoryName) => {
  const matchedCategory = categoryList.filter((category) => category.name === categoryName)
  if (matchedCategory.length > 0) return matchedCategory[0]

  return categoryList.map((category) => {
    if (category.children) {
      return findCategory(category.children, categoryName)
    }
  }).filter(value => value !== undefined)[0]
}
</script>

<style scoped lang="scss">
.custom {
  font-size: 12px;
  line-height: 1.1;
}

</style>

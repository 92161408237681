<template>
  <v-sheet elevation="0">
    <v-row class="justify-start">
      <v-text-field
        class="mt-6 mx-5"
        :value="limit"
        type="number"
        outlined="outlined"
        dense="dense"
        label="出題数"
        name="number of question"
        @input="updateLimit"
      />
    </v-row>
    <v-row class="align-center justify-space-between">
      <v-checkbox
        class="mx-6"
        :input-value="random"
        label="ランダム出題"
        inset="inset"
        @change="updateRandom"
      />
    </v-row>
    <v-row
      v-show="isLogin"
      class="align-center justify-space-between"
    >
      <v-checkbox
        class="mx-6"
        :input-value="wrong"
        label="前回間違えた問題を出題"
        inset="inset"
        @change="updateWrongMode"
      />
    </v-row>
    <v-row
      v-show="isLogin"
      class="align-center justify-start no-gutters"
    >
      <v-col
        class="d-flex mx-3 align-center"
        cols="12"
      >
        <v-checkbox
          v-model="wrongCountOn"
          class="shrink mt-0"
          inset="inset"
          hide-details="hide-details"
          @click="disableWrongModeFlg"
        />
        <v-row class="align-center justify-start no-gutters">
          <span class="theme--light v-label">直近3回中</span>
          <v-select
            class="shrink py-2 pr-2 pl-sm-2 mr-1"
            :items="wrongCountOptions"
            :value="wrong_count"
            suffix="回"
            dense="dense"
            outlined="outlined"
            hide-details="hide-details"
            :disabled="!wrongCountOn"
            @change="updateWrongCount"
          /><span class="theme--light v-label">間違えた問題を出題</span>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-show="isLogin"
      class="align-center justify-space-between"
    >
      <v-checkbox
        class="mx-6"
        :input-value="unsolved"
        label="まだ解いていない問題を出題"
        inset="inset"
        @change="updateUnsolvedMode"
      />
    </v-row>
    <v-row
      v-show="isLogin"
      class="align-center justify-space-between"
    >
      <v-checkbox
        class="mx-6"
        :input-value="bookmark"
        label="ブックマークした問題を出題"
        inset="inset"
        @change="updateBookmarkMode"
      />
    </v-row>
    <v-row class="align-center justify-space-between">
      <v-checkbox
        class="mx-6"
        :input-value="hideAnswerMode"
        label="全て解き終わってから回答を確認する"
        inset="inset"
        @change="updateHideAnswerMode"
      />
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "SelectOption",
  data() {
    return {
      wrongCountOn: false,
      wrongCountOptions: [1, 2, 3]
    }
  },
  computed: {
    ...mapGetters(['limit', 'random', 'wrong', 'bookmark', 'wrong_count', 'isLogin', 'hideAnswerMode', 'unsolved'])
  },
  watch: {
    wrongCountOn(flg) {
      if (!flg) {
        this.$store.dispatch('updateSelectedConditions', { wrong_count: null })
      }
    }
  },
  mounted() {
    this.wrongCountOn = !!this.wrong_count
  },
  methods: {
    updateRandom(e) {
      this.$store.dispatch('updateSelectedConditions', { random: !!e })
    },
    updateWrongMode(e) {
      const wrongModeFlg = !!e;
      if (wrongModeFlg) {
        this.wrongCountOn = false
        this.updateWrongCount(null)
      }
      this.$store.dispatch('updateSelectedConditions', { wrong: wrongModeFlg })
      this.$store.dispatch('updateSelectedConditions', { unsolved: false })
    },
    updateUnsolvedMode(e) {
      this.$store.dispatch('updateSelectedConditions', { unsolved: !!e, wrong: false, wrong_count: null })
      this.wrongCountOn = false;
    },
    updateBookmarkMode(e) {
      this.$store.dispatch('updateSelectedConditions', { bookmark: !!e })
    },
    updateWrongCount(e) {
      this.$store.dispatch('updateSelectedConditions', { wrong_count: e })
    },
    updateLimit(e) {
      const limit = validateNumber(Number(e)) ? Number(e) : this.limit

      this.$store.dispatch('updateSelectedConditions', { limit })
    },
    updateHideAnswerMode(e) {
      this.$store.dispatch('updateHideAnswerMode', e)
    },
    disableWrongModeFlg() {
      this.$store.dispatch('updateSelectedConditions', { wrong: false, unsolved: false })
    }
  },
}
const validateNumber = (value) => {
  if (value === null) return true
  return !isNaN(value) && value >= 0
}
</script>

<style scoped>

</style>
